import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery, Link } from "gatsby";
import Helmet from "react-helmet";
import "../styles/header.css";
import favicon from "../../static/favicon.ico";

export default function Layout({ children }) {

	const data = useStaticQuery(graphql`
    query {
      logo: allImageSharp(filter: {original: {src: {regex: "/logo/"}}}) {
        edges {
          node {
            gatsbyImageData(width: 200, layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
    }
  `);

	const faviconLinks = [
		{ rel: "icon", type: "image/x-icon", href: favicon },
		{ rel: "shortcut icon", type: "image/x-icon", href: favicon },
	];
  
	return <>
		<Helmet
			htmlAttributes={{
				lang: "en",
			}}
			link={faviconLinks}
		>
			<title>Take a Step Toward Wellness With Dignity Health Bakersfield</title>
			<meta name="description" content="You're taking care of your health and now's the time to reach out and inspire a friend to do the same. Learn how with Dignity Health Bakersfield." />
		</Helmet>
		<div id="parent-container">
			<div id="header">
				<Link to="/" title="Health It Forward, Bakersfield!">
					<GatsbyImage
						image={data.logo.edges[0].node.gatsbyImageData}
						alt="Dignity Health logo" />
				</Link>
			</div>
			{children}
		</div>
	</>;
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
};